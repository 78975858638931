
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





















.not-found {
  @include fluid(
    padding-top,
    (
      mob: 175px,
      xl: 315px,
    )
  );

  .category {
    color: $tango;
  }
}

.text__p {
  width: 80%;
}

@include mq(s) {
  .not-found .wrapper {
    display: flex;
    align-items: center;
  }

  .text {
    flex-basis: 70%;
    margin-bottom: 0;
    padding-right: 40px;
  }

  .image {
    flex: 1;
  }
}

@include mq(l) {
  .text {
    flex-basis: 60%;
    padding-right: 100px;
  }
}
