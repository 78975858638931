
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



















































































.popup,
[class*='popup--'] {
  @include get-all-space;

  position: fixed;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &.is-visible {
    z-index: 100;
    pointer-events: auto;
  }
}

.background {
  @include get-all-space;

  background-color: transparent;
  opacity: 0;
  transition: opacity $ease-in 0.3s;

  .popup.is-visible & {
    background-color: $black;
    opacity: 0.7;
  }
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 76rem;
  margin: 0 2rem;
  // background: $tango url('../assets/images/pattern-cta.svg') top repeat-x;
  background-color: #EC6607;
  opacity: 0;
  transform: translateY(50px);
  transition: transform $ease-out 0.3s 0.2s, opacity $ease-out 0.3s 0.25s;

  .popup.is-visible & {
    opacity: 1;
    transform: translateY(0);
  }
}

.content__close {
  position: absolute;
  z-index: 10;
  top: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 0;
  background: transparent;
  border: 0;
  fill: $c-black;
  cursor: pointer;
}

.content__bg {
  @include image-fit;

  position: relative;

  @include mq(mob) {
    position: absolute;
  }
}

.content__cta {
  // prettier-ignore
  @include fluid(padding-right, (mob: 20px, l: 60px));
  // prettier-ignore
  @include fluid(padding-left, (mob: 180px, s: 390px));

  position: relative;
  padding-right: 2rem;
  padding-left: 2rem;
  background: transparent;

  ::v-deep .title {
    // prettier-ignore
    @include fluid(font-size, (mob: 24px, xl: 36px));
  }

  ::v-deep &::before {
    background: transparent;
  }

  @include mq($until: mob) {
    padding-top: 0;
  }
}

.content__footer {
  // prettier-ignore
  @include fluid(right, (xxs: -10px, xxl: -40px));

  right: -4rem;
  bottom: -2rem;
  display: flex;
  align-items: flex-end;
}
