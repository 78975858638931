$breakpoint: m;

.col,
[class*='col--'] {
  @include mq($breakpoint) {
    display: flex;
  }
}

.col-item,
[class*='col-item--'] {
  flex-shrink: 0;

  @include mq($breakpoint) {
    &[class*='--7'] {
      width: inside-col(7);
    }

    &[class*='--9'] {
      width: inside-col(9);
    }
  }
}
