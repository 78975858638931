
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        

























































































.footer__block {
  position: relative;
  background: $tango;
  color: $white;
}

.footer__layer {
  @include get-all-space;

  overflow: hidden;

  img {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: col(2);
    width: auto;
    height: 80%;
  }
}

.footer__content {
  position: relative;
  z-index: 5;
}

.footer__logo {
  // prettier-ignore
  @include fluid(width,(xxs: 85px, xxl: 150px));

  flex-shrink: 0;
  fill: $white;

  svg {
    width: 100%;
    height: auto;
  }
}

.footer__logo-container {
  // prettier-ignore
  @include fluid(width,(xxs: 300px, xxl: 400px));

  display: flex;
  gap: 3rem;
}

.footer__nav {
  padding: 40px 0;

  ul {
    list-style: none;
    margin: 0;
    margin-bottom: 25px;
    padding: 0;
    column-count: 2;
    column-gap: 20px;
  }

  li {
    margin-bottom: 10px;

    a,
    button {
      padding: 0;
      font-size: 11px;
      text-transform: uppercase;
      background: unset;
      border-width: 0;
      transition: color 0.2s;

      &:hover {
        color: $tango;
      }
    }
  }
}

.mentions {
  li {
    a,
    button {
      color: $gray;
      font-size: 13px;
      text-transform: none;
      cursor: pointer;
    }
  }
}

.footer__slogan {
  ::v-deep span {
    white-space: nowrap;
  }
}

.footer__social {
  display: flex;
  align-items: center;

  li + li {
    margin-left: 5px;
  }

  svg {
    transition: fill 0.2s;
  }

  a:hover {
    svg {
      fill: $c-white;
    }
  }
}

.footer__credits {
  display: flex;
  width: 14px;
  height: 14px;

  svg {
    width: 100%;
    height: auto;
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: $tango;
    }
  }
}

@include mq(xl) {
  .footer__content {
    // prettier-ignore
    @include fluid(padding-bottom, (s: 100px, xl: 150px));

    display: flex;
    justify-content: space-between;
  }

  .mentions {
    margin-right: 20px !important;
    margin-left: auto !important;
  }

  .footer__left {
    flex-basis: 50%;
    padding-right: 100px;
  }

  .footer__newsletter {
    flex: 1;
    max-width: 500px;
    margin-top: auto;
    padding-bottom: 15px;
  }

  .footer__social {
    margin-bottom: 0;
  }

  .footer__nav .wrapper {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .footer__nav {
    padding: 20px 0;
  }

  .footer__nav ul {
    display: flex;
    align-items: center;
    columns: auto;
    column-gap: 0;
    margin: 0;

    li {
      margin: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors, selector-max-type */
      a {
        font-weight: 700;
      }
    }
  }
}
