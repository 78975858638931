.date-picker {
  * > input {
    width: 100%;
    padding: 0.5rem 0 0.3rem;
    font-size: 1.8rem;
    border: 0;
  }

  .vdp-datepicker__calendar {
    border: 1px solid $c-black;
  }

  .day__month_btn {
    color: $c-white !important;
    font-size: 1.5rem;
    background: $c-black !important;
  }

  .prev,
  .next {
    background: $c-black;

    &:hover {
      background: $tango !important;
    }
  }

  .prev {
    &::after {
      border-right: 10px solid $tango !important;
    }

    &:hover {
      &::after {
        border-right: 10px solid $c-white !important;
      }
    }
  }

  .next {
    &::after {
      border-left: 10px solid $tango !important;
    }

    &:hover {
      &::after {
        border-left: 10px solid $c-white !important;
      }
    }
  }

  .day-header {
    height: 3rem !important;
    font-size: 1.1rem !important;
    line-height: 3rem !important;
    background: $c-gray-lightest;
  }

  .cell {
    font-size: 1.5rem;

    &:hover {
      color: $tango;
      border-top: 1px solid transparent !important;
      border-left: 1px solid transparent !important;
    }
  }

  .day {
    border-right: 1px solid $c-gray-lightest !important;
    border-bottom: 1px solid $c-gray-lightest !important;
  }
}
