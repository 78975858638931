
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        










.link,
[class*='link--'] {
  @include brown;
  @include fluid(
    font-size,
    (
      mob: 13px,
      xl: 15px,
    )
  );

  display: inline-flex;
  align-items: center;
  color: $tango;
  font-weight: 700;

  &[class*='--gray'] {
    color: $silver-chalice;
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }
}

.link__arrow {
  overflow: hidden;
  margin-left: 6px;

  svg {
    fill: $tango;
    width: 45px;
    transform: translate3d(-20px, 0, 0);
    will-change: transform;
    transition: 0.4s transform;
  }

  [class*='link--'][class*='--gray'] & {
    svg {
      fill: $silver-chalice;
    }
  }

  [class*='link--'][class*='--reverse'] & {
    transform: translate3d(-20px, 0, 0) rotate(180deg);
  }
}

.link:hover {
  .link__arrow svg {
    transform: translate3d(0, 0, 0);

    [class*='link--'][class*='--reverse'] & {
      transform: translate3d(0, 0, 0) rotate(180deg);
    }
  }
}
