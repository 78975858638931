
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




















.newsletter p {
  @include brown;
  @include fluid(
    font-size,
    (
      mob: 18px,
      xl: 20px,
    )
  );
  font-weight: 700;
}

.newsletter__input {
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1px solid $black;
}

::placeholder {
  color: $black;
}

.newsletter input {
  border: 0;
  background: transparent;
  flex: 1;
  font-weight: 400;
  @include brown;
  @include fluid(
    font-size,
    (
      mob: 14px,
      xl: 16px,
    )
  );
}

.newsletter .btn {
  cursor: pointer;
  text-transform: uppercase;
}
