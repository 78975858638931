.tabs,
[class*='tabs--'] {
  display: flex;
  max-width: 68rem;
}

.tabs__tab {
  margin-right: 0.5rem;
  line-height: 1;
  white-space: nowrap;
  border-bottom: 0.3rem solid $alto;
  transition: flex-grow 0.5s;
  pointer-events: none;

  &.is-active {
    flex: 1;
    border-bottom: 0.3rem solid $tango;
  }

  &.is-clickable {
    pointer-events: initial;
  }
}

.tabs__tab__link {
  display: block;
  padding: 0 3rem 1rem 0;
  color: $alto !important;
  font-size: 1.3rem;

  .is-active & {
    color: $c-black !important;
  }

  @include mq(s) {
    padding: 0 6.5rem 1rem 0;
    font-size: 1.5rem;
  }
}
