
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        













































































.header,
[class*='header--'] {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;

  &.is-visible {
    opacity: 1;
  }
}

.header__bar {
  @include fluid(
    height,
    (
      mob: 65px,
      xl: 80px,
    )
  );
  @include fluid(
    padding-left,
    (
      mob: 15px,
      xl: 25px,
    )
  );
  @include fluid(
    padding-right,
    (
      mob: 15px,
      xl: 25px,
    )
  );

  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $black;
  background: transparent;
  transition: background 0.4s;
  fill: $black;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    height: 1px;
    background: $alto;
    transition: opacity 0.4s;
  }

  &::after {
    right: 0;
    left: auto;
  }

  .header.open &,
  .header.mini & {
    &::before,
    &::after {
      opacity: 0;
    }
  }

  [data-type='is-light'] & {
    &::before,
    &::after {
      background: rgba($alto, 0.3);
    }
  }

  @include mq(m) {
    &::before,
    &::after {
      width: 42%;
    }
  }

  @include mq(xxxl) {
    &::before,
    &::after {
      width: 44%;
    }
  }
}

.header__bar__half {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 30%;

  @include mq(m) {
    width: 40%;
  }
}

.header__menu {
  display: none;

  @include mq(m) {
    display: block;
  }
}

.header__menu__link {
  position: relative;
  display: inline-block;
  color: $c-black;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  transition: opacity 0.3s, color 0.2s;

  &:hover {
    color: $tango !important;
  }

  &:not(:last-child) {
    margin-right: 6rem;
  }

  [data-type='is-light']:not(.mini) & {
    color: $c-white;

    &::before {
      background: $c-white;
    }
  }

  .header.open & {
    opacity: 0;
  }

  @include mq(xxl) {
    &:not(:last-child) {
      margin-right: 10rem;
    }
  }
}

.header__logo {
  @include fluid(
    width,
    (
      mob: 106px,
      xl: 191px,
    )
  );

  display: inline-block;
  font-size: 0;
  transform: translateY(50%) translateY(-17px);
  transition: transform 0.4s, fill 0.2s, opacity 0.4s;
  fill: $black;
  will-change: transform;

  svg {
    width: 80%;
    height: auto;
  }

  .open & {
    opacity: 0;
    transform: scale(0.5);

    @include mq(s) {
      transform: scale(0.36);
    }
  }

  [data-type='is-light'] & {
    fill: $c-white;
  }

  @include mq(s) {
    svg {
      width: 80%;
      height: auto;
    }
  }
}

.header__menu__lang {
  margin-left: auto;
}

.header__toggler {
  @include expand(30px);

  position: relative;
  z-index: 4;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 12px;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  span {
    display: inline-block;
    width: inherit;
    height: 2px;
    background: $black;
    transition: background 0.2s, transform 0.2s;
  }

  &:hover {
    span {
      background: $tango !important;

      &:first-child {
        transform: translateX(-3px);
      }

      &:last-child {
        transform: translateX(3px);
      }
    }
  }

  .header.open & {
    span {
      &:first-child {
        transform: translateY(5px) rotate(45deg);
      }

      &:last-child {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }

  [data-type='is-light']:not(.mini) & {
    span {
      background: $c-white;
    }
  }
}

.header__nav {
  @include get-all-space;

  position: fixed;
  z-index: 5;
  display: none;
  overflow: auto;
  background: $white;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @include mq(s) {
    overflow: hidden;
    margin-top: 0;
  }
}

.header.open {
  .header__bar {
    transform: translateY(0%);
    background: $white;
  }

  @include mq(s) {
    .header__bar {
      background: transparent;
    }

    .header__toggler {
      span {
        background: $c-white;
      }
    }
  }
}

.header.mini:not(.open) {
  .header__bar {
    opacity: 0;
    transition: opacity 0.4s, background 0.4s;
  }

  .header__logo {
    transform: scale(0.5);

    @include mq(s) {
      transform: scale(0.36);
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.goingUp {
    .header__bar {
      background: $white;
      opacity: 1 !important;
      transform: translateY(0%);
    }

    .header__logo {
      fill: $c-black;
      opacity: 1;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.goingDown {
    // Prevent misclicks on header elements since it is visually hidden
    pointer-events: none;
  }
}

.header.white:not(.mini):not(.open) {
  .header__logo {
    fill: $white;
  }

  .header__toggler span {
    background: $white;
  }

  &::v-deep {
    .lang__current {
      color: $white;

      svg {
        fill: $white;
      }
    }
  }
}
