.cookiebar {
  position: relative;
  z-index: 999;

  .cookiebar__title {
    display: block;
    margin-bottom: 20px;
    font-family: manier, serif;
    font-size: 48px;
  }

  .cookiebar-selection__content__title {
    display: block;
    margin: 0 0 20px;
    font-size: 48px;
  }

  .cookiebar__item__header__title {
    font-size: 30px;
  }

  .cookiebar__item__details__description {
    font-size: 11px;
  }

  .cookiebar__item__details h3 {
    font-size: 18px;
  }

  .cookiebar__actions {
    --gap: 10px;

    @include mq($until: s) {
      flex-direction: column-reverse;
      gap: var(--gap);
      padding-block: 15px;

      /* stylelint-disable-next-line max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
      .cookiebar__actions__primary {
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        width: 100%;

        /* stylelint-disable-next-line max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
        [class*='cookiebar__action'] {
          width: 100%;
        }
      }
    }
  }

  .cookiebar-selection__footer {
    --gap: 10px;

    height: unset;

    &.cookiebar__actions {
      flex-direction: column-reverse;
      gap: var(--gap);
      padding-block: 15px;

      @include mq($until: s) {
        /* stylelint-disable-next-line max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
        .cookiebar__actions__primary {
          display: flex;
          flex-direction: column;
          gap: var(--gap);
          width: 100%;

          /* stylelint-disable-next-line max-nesting-depth, selector-max-combinators, selector-max-compound-selectors */
          [class*='cookiebar__action'] {
            width: 100%;
          }
        }
      }
    }
  }
}
