
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        








































































































.cta,
[class*='cta--'] {
  position: relative;
  overflow: hidden;
  padding: 6rem 0;
  background-color: $tango;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: url('../assets/images/pattern-cta.svg') top repeat-x;
    background-size: contain;
    pointer-events: none;
  }

  &[class*='--big'] {
    background-color: $cocoa-brown;

    &::before {
      opacity: 0.5;
    }
  }

  &[class*='--blue'] {
    background-color: transparent;
    background-image: radial-gradient(#237c9d, $orient);

    &::before {
      opacity: 0.5;
    }
  }

  &[class*='--bg-picture'] {
    padding-right: col(2);
    padding-left: col(2);
    background: $school-bus-yellow;

    &::before {
      display: none;
    }
  }

  &[class*='--light'] {
    padding: 0;
    padding-bottom: 3.5rem;
    background-color: $c-white;
    border-bottom: 1px solid $c-black;
  }

  @include mq(m) {
    overflow: visible;
    padding: 6rem 0;
  }

  @include mq(xxxl) {
    &[class*='--bg-picture'] {
      padding-right: col(3);
      padding-left: col(3);
    }
  }
}

.inner {
  position: relative;
  z-index: 2;

  [class*='cta--'][class*='--bg-picture'] & {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 120px, xxxl: 110px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 30px, xxxl: 100px));

    position: static;
  }

  [class*='cta--'][class*='--reverse'] & {
    display: flex;
    flex-direction: column-reverse;
  }

  @include mq(s) {
    [class*='cta--'][class*='--reverse'] & {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  [class*='cta--'][class*='--bg-picture'] & {
    position: relative;
    z-index: 2;
  }

  [class*='cta--'][class*='--reverse'] & {
    // prettier-ignore
    @include fluid(gap,(xxs: 30px, xxl: 40px));

    ::v-deep .btn {
      flex-shrink: 0;
    }
  }

  @include mq(m) {
    [class*='cta--'][class*='--big'] &,
    [class*='cta--'][class*='--bg-picture'] & {
      max-width: inside-col(9);
    }
  }

  @include mq(xl) {
    [class*='cta--'][class*='--big'] &,
    [class*='cta--'][class*='--bg-picture'] & {
      max-width: inside-col(7);
    }

    [class*='cta--'][class*='--reverse'] & {
      flex-direction: row;
      align-items: center;
    }
  }
}

.title {
  @include manier;
  // prettier-ignore
  @include fluid(margin-bottom, (mob: 25px, xl: 30px));
  // prettier-ignore
  @include fluid(font-size, (mob: 28px, xl: 48px));

  color: $c-white;
  font-weight: 700;
  line-height: 1.2;

  [class*='cta--'][class*='--bg-picture'] & {
    margin-bottom: 1.5rem;
    color: $c-black;
  }

  [class*='cta--'][class*='--light'] & {
    // prettier-ignore
    @include fluid(font-size, (mob: 20px, xl: 28px));

    color: $c-black;
  }

  @include mq(m) {
    [class*='cta--'][class*='--light'] & {
      max-width: 35rem;
    }
  }
}

h2.title {
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.subtitle {
  @include fluid(
    font-size,
    (
      mob: 15px,
      xl: 18px,
    )
  );

  display: block;
  margin-bottom: 4rem;
  color: $c-white;

  [class*='cta--'][class*='--bg-picture'] & {
    color: $c-black;
  }
}

.picture {
  max-width: 35rem;
  max-height: 35rem;
  margin-top: 2rem;
  margin-bottom: -6rem;

  img {
    margin-left: auto;
  }

  [class*='cta--'][class*='--reverse'] & {
    margin-bottom: 2rem;

    img {
      margin-left: 0;
    }
  }

  [class*='cta--'][class*='--bg-picture'] & {
    @include get-all-space;

    right: -35%;
    left: auto;
    overflow: hidden;
    width: 30rem;
    max-width: none;
    height: 45%;
    max-height: none;
    margin-top: 0;

    img {
      @include image-fit($position: bottom);
    }
  }

  @include mq(mob) {
    [class*='cta--'][class*='--bg-picture'] & {
      right: -20%;
      height: 50%;
    }
  }

  @include mq(xs) {
    [class*='cta--'][class*='--bg-picture'] & {
      right: 0;
      width: 45%;
      height: 80%;

      img {
        @include image-fit($position: left);
      }
    }
  }

  @include mq(m) {
    [class*='cta--'][class*='--bg-picture'] & {
      right: 0;
      max-width: none;
      height: auto;
    }
  }

  @include mq(m) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.products {
  position: relative;
  display: none;
  flex: 1;
  margin-bottom: -6rem;

  [class*='cta--'][class*='--big'] & {
    display: block;
  }

  @include mq(m) {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
}

.products-inner {
  @include get-all-space;

  overflow: hidden;

  img {
    position: absolute;
  }

  @include mq(m) {
    width: 125%;
  }
}

.image {
  opacity: 0;
}

.product-bar {
  // prettier-ignore
  @include fluid(height, (mob: 260px, xl: 535px));
  // prettier-ignore
  @include fluid(margin-right, (mob: 40px, m: 70px));

  position: relative;
  z-index: 1;
  display: block;
  margin-right: 1rem;
  margin-left: auto;
  transform: translateY(10%) rotate(-15deg);

  @include mq(m) {
    margin-right: -4rem;
    transform: translateY(-10%) rotate(-15deg);
  }

  @include mq(xl) {
    transform: translateY(-30%) rotate(-15deg);
  }
}

.product-mini {
  // prettier-ignore
  @include fluid(height, (mob: 260px, m: 450px));

  right: 25%;
  transform: translateY(65%) rotate(30deg);

  @include mq(m) {
    z-index: 10;
  }

  @include mq(l) {
    height: 400px;
    transform: translateY(65%) rotate(20deg);
  }

  @include mq(xxxl) {
    right: 30%;
    height: 450px;
    transform: translateY(50%) rotate(20deg);
  }
}

.product-orange {
  // prettier-ignore
  @include fluid(height, (mob: 160px, xl: 350px));

  bottom: 0;
  left: 12%;
  transform: translateY(10%) rotate(-30deg);

  @include mq(m) {
    top: 28%;
    bottom: auto;
    left: 12%;
    transform: translateY(0) rotate(-30deg);
  }

  @include mq(xl) {
    top: 10%;
    left: 15%;
    height: 410px;
  }
}

.product-red {
  // prettier-ignore
  @include fluid(width, (mob: 125px, xl: 315px));

  top: 25%;
  left: 20%;
  transform: translateY(-10%) rotate(30deg);

  @include mq(m) {
    top: -5%;
    left: 0;
    transform: translateY(0) rotate(30deg);
  }

  @include mq(xl) {
    left: 20%;
  }
}

.product-fragments {
  top: 30%;
  right: 0;
  width: 50%;

  @include mq(m) {
    top: 10%;
    width: 60%;
  }
}
