
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        































.lang {
  @include fluid(
    font-size,
    (
      mob: 13px,
      xl: 15px,
    )
  );

  display: flex;
  align-items: center;
}

.cart {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  padding: 1rem;
  color: $c-white;
  background: $tango;
  border-radius: 50%;

  &:hover {
    background: darken($tango, 5);
  }

  @include mq(xl) {
    border-radius: 0;
  }
}

.cart__label {
  display: none;

  @include mq(xl) {
    display: block;
    margin-left: 1rem;
  }
}

.cart__icon {
  width: 1.8rem;
  height: 1.8rem;
  fill: $c-white;
  transition: fill 0.1s;
}

.lang__current {
  @include expand(10px);

  position: relative;
  z-index: 40;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: $tango !important;

    svg {
      fill: $tango !important;
    }
  }

  [data-type='is-light']:not(.mini) & {
    color: $c-white;
  }

  svg {
    @include fluid(
      margin-left,
      (
        mob: 5px,
        xl: 10px,
      )
    );

    position: relative;
    vertical-align: middle;
    top: -2px;
    transition: fill 0.2s;

    [data-type='is-light']:not(.mini) & {
      fill: $c-white;
    }
  }
}

.lang.open {
  .lang__current {
    color: $black !important; /* stylelint-disable-line declaration-no-important */

    svg {
      fill: $black !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.lang__list {
  @include fluid(
    padding-top,
    (
      mob: 50px,
      xl: 65px,
    )
  );
  @include fluid(
    width,
    (
      mob: 50px,
      xl: 70px,
    )
  );

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding-top: 50px;
  padding-bottom: 15px;
  text-align: left;
  background: $white;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    @include fluid(
      font-size,
      (
        mob: 13px,
        xl: 15px,
      )
    );
    @include fluid(
      padding-left,
      (
        mob: 5px,
        xl: 10px,
      )
    );

    display: block;
    color: $gray;
  }

  li + li {
    margin-top: 4px;
  }

  a:hover {
    color: $tango;
  }
}
