
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        


























































$w: 1.3rem;
$h: 1.1rem;
$s: 1.5;
$p: 0.6rem;
$bg: $c-gray-darkest;
$c: $c-gray-medium;

.env-switcher {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 $p 0 0;
  color: $c;
  background-color: $bg;
  transform: translateX(calc(100% - #{(($w * $s) + (2 * $p))}));

  &.is-open {
    transform: translateX(0);
  }
}

.env-switcher__toggler {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $p;
  color: $c;
  line-height: 1;
  background-color: $bg;
  border: none;
  fill: currentColor;

  svg {
    width: $w * $s;
    height: $h * $s;
    fill: inherit;
  }
}

.env-switcher__content {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $p;
  margin: 0;
  padding: $p;
  border-left: 1px dotted $c;
}

.env-switcher__content__item__label,
.env-switcher__content__item__select {
  font-size: 0.8em;
}

.env-switcher__content__item__select {
  color: $c;
  background-color: $bg;
  border: 1px solid $c;
  border-radius: 4px;
}
