
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



































.loader {
  @include get-all-space;

  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $tango;
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 0.25s, transform 0.25s;

  .app.is-loading & {
    opacity: 1;
    transform: scale(1);
  }

  &.is-hidden {
    display: none;
  }
}

.loader__logo {
  width: 50vw;
  max-width: 30rem;
  height: 50vw;
  max-height: 30rem;
  fill: $c-white;
  animation: logo 0.8s linear infinite alternate;
}

@keyframes logo {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.transition-1,
.transition-2 {
  position: fixed;
  z-index: 100;
  bottom: 110%;
  left: -10%;
  width: 120%;
  height: 100%;
  background: $tango;
  transform: rotate(-5deg);
}

.transition-2 {
  background: lighten($tango, 5);
}

.debug {
  position: fixed;
  z-index: 9999;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
  padding: 1rem;
  color: #fff;
  font-size: 2em;
  background-color: #f90;

  a {
    padding: 1rem;
  }
}
