@font-face {
  font-family: 'brown';
  src: url('../assets/fonts/brown/light/light.woff2') format('woff2'),
    url('../assets/fonts/brown/light/light.woff') format('woff'),
    url('../assets/fonts/brown/light/light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'brown';
  src: url('../assets/fonts/brown/regular/regular.woff2') format('woff2'),
    url('../assets/fonts/brown/regular/regular.woff') format('woff'),
    url('../assets/fonts/brown/regular/regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'brown';
  src: url('../assets/fonts/brown/bold/bold.woff2') format('woff2'),
    url('../assets/fonts/brown/bold/bold.woff') format('woff'),
    url('../assets/fonts/brown/bold/bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'manier';
  src: url('../assets/fonts/manier/light/light.woff2') format('woff2'),
    url('../assets/fonts/manier/light/light.woff') format('woff'),
    url('../assets/fonts/manier/light/light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'manier';
  src: url('../assets/fonts/manier/bold/bold.woff2') format('woff2'),
    url('../assets/fonts/manier/bold/bold.woff') format('woff'),
    url('../assets/fonts/manier/bold/bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

html {
  font-size: calc(1em * 0.625);
}

html,
body {
  @include brown;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 1.5rem;

  @include mq(m) {
    font-size: 1.8rem;
  }
}

h1,
.h1 {
  @include manier;
  @include fluid(font-size,
    (xxs: 32px,
      xl: 84px,
    ));
  font-weight: 700;
  line-height: 1.15;
}

h2,
.h2 {
  @include manier;
  @include fluid(font-size,
    (mob: 28px,
      xl: 48px,
    ));
  font-weight: 700;
  line-height: 1.18;
}

h3,
.h3 {
  @include manier;
  @include fluid(font-size,
    (mob: 24px,
      xl: 36px,
    ));
  font-weight: 700;
  line-height: 1.333;
}

h4,
.h4 {
  @include manier;
  @include fluid(font-size,
    (mob: 20px,
      xl: 28px,
    ));
  font-weight: 700;
  line-height: 1.333;
}

h5,
.h5 {
  @include manier;
  @include fluid(font-size,
    (mob: 17px,
      xl: 22px,
    ));
  font-weight: 700;
  line-height: 1.333;
}

.lead {
  @include brown;
  @include fluid(font-size, (mob: 18px, xl: 24px));
  @include fluid(margin-bottom, (mob: 25px, xl: 40px));

  font-weight: 300;
  line-height: 1.5;
}

p,
ul {
  @include brown;
  @include fluid(font-size,
    (mob: 15px,
      xl: 18px,
    ));
  font-weight: 300;
  line-height: 1.65;
}

.small {
  @include brown;
  @include fluid(font-size,
    (mob: 13px,
      xl: 15px,
    ));
  font-weight: 300;
  line-height: 1.4;
}

.tiny {
  @include brown;
  @include fluid(font-size,
    (mob: 11px,
      xl: 13px,
    ));
  font-weight: 300;
  line-height: 1.4;
}

.category {
  @include brown;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1.86px;
}

.tag {
  @include brown;
  font-size: 9px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1.57px;
  color: $tango;

  @include mq(m) {
    font-size: 11px;
  }
}

.baseline {
  @include manier;
  font-size: 2.4rem;

  @include mq(s) {
    font-size: 3.6rem;
  }
}

blockquote {
  p {
    @include manier;
    @include fluid(font-size,
      (mob: 28px,
        xl: 48px,
      ));
    font-weight: 300;
    line-height: 1.2;
    margin: 0 !important;
  }

  footer {
    @include brown;
    // prettier-ignore
    @include fluid(margin-top, (xxs: 40px, xl: 80px));
    @include fluid(font-size,
      (mob: 13px,
        xl: 15px,
      ));
    font-weight: 300;
    line-height: 1.4;
    text-align: right;
  }

  footer span {
    color: $tango;
  }
}
