.wysiwyg,
[class*='wysiwyg--'] {
  h1,
  h2,
  h3,
  h4 {
    margin: 2rem 0;
  }

  b,
  strong {
    color: $tango;
  }

  p {
    &:not(.lead) {
      margin-bottom: 2.5rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    line-height: 1.6;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &::before {
      content: '•';
      align-items: center;
      margin-right: 1rem;
      color: $tango;
    }
  }
}
