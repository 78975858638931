
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        














































































$breakpoint: s;

.mega-menu {
  @include fluid(
    margin-top,
    (
      mob: 65px,
      xl: 75px,
    )
  );

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-right: 0;
  padding-left: 0;

  @include mq($breakpoint) {
    flex-direction: row;
    margin-top: 0;
  }
}

.aside {
  position: relative;
  display: none;
  width: col(8);
  margin-right: col(2);
  background: $cocoa-brown url('../assets/images/pattern-menu.svg') top right
    no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 50vw;
    height: 100%;
    background: $cocoa-brown;
  }

  @include mq($breakpoint) {
    display: block;
  }

  @include mq(xxxl) {
    width: col(10);
    margin-right: col(3);
  }
}

.aside-image {
  position: absolute;
  max-width: 50%;
  bottom: 0;

  &.mini {
    right: 15%;
    opacity: 0;
    transform: translate(0, 80px);
  }

  &.orange {
    right: 50%;
    bottom: 7%;
    opacity: 0;
    transform: translate(80px, 80px);
  }

  &.fragments {
    right: 45%;
    bottom: 32%;
    opacity: 0;
    transform: translate(0, 80px);
  }

  &.bar {
    right: -3%;
    bottom: 20%;
    opacity: 0;
    transform: translate(-80px, 80px);
  }

  &.redberry {
    right: -10%;
    bottom: 50%;
    opacity: 0;
    transform: translate(-80px, 80px);
  }

  &.red {
    right: 50%;
    bottom: 50%;
    opacity: 0;
    transform: translate(80px, 80px);
  }
}

.logo {
  display: none;
  width: 6rem;
  height: 6rem;
  margin-bottom: 5rem;

  @include mq(s) {
    display: block;
  }
}

.main,
.links {
  li {
    overflow: hidden;
    opacity: 0;
    transform: translateY(100px);
  }

  @include mq($breakpoint) {
    text-align: left;
  }
}

.main {
  @include fluid(
    margin-bottom,
    (
      mob: 30px,
      xl: 100px,
    )
  );

  margin-top: 3rem;
}

.links {
  @include mq($breakpoint) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: auto;
  }
}

.main-link {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 36px,
      xl: 72px,
    )
  );

  color: $c-black;
  font-weight: 700;
  line-height: 1.2;
  transition: color 0.2s;

  ::v-deep .icon {
    fill: $tango;
  }

  ::v-deep .link__arrow {
    display: none;
  }

  &.router-link-active {
    color: $tango;
  }

  &:hover {
    color: $tango;

    ::v-deep .icon {
      opacity: 1;
      transform: translateX(2.5rem);
    }
  }

  @include mq($breakpoint) {
    display: flex;
    align-items: center;

    ::v-deep .link__arrow {
      display: block;
      margin-left: 2rem;
    }

    ::v-deep .icon {
      display: block;
      margin: 1rem 0 0;
      opacity: 0;
      transition: opacity 0.2s, transform 0.2s;
    }
  }
}

.secondary-link {
  @include fluid(
    font-size,
    (
      mob: 11px,
      xl: 13px,
    )
  );

  color: $silver-chalice;
  font-weight: 700;
  line-height: 3.5;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: color 0.2s;

  &.router-link-active {
    color: $c-black;
  }

  ::v-deep .link__arrow {
    display: none;
  }

  &:hover {
    color: $c-black;
  }

  @include mq($breakpoint) {
    line-height: 1;
  }
}

.mobile-footer {
  margin-top: auto;
  opacity: 0;
  transform: translateY(50px);

  @include mq($breakpoint) {
    display: none;
  }
}
