.form-wrapper {
  display: flex;
  justify-content: space-between;

  @include mq($until: xs) {
    flex-wrap: wrap;
  }
}

.form-item,
[class*='form-item--'] {
  flex-basis: 100%;
  margin: 0 0 3rem;
  border-bottom: 0.1rem solid $alto;


  @include mq(xs) {
    &[class*='--half'] {
      flex-basis: calc(50% - 1rem);
    }
  }

  @include mq(m) {
    margin: 0 0 6rem;

    &[class*='--half'] {
      flex-basis: calc(50% - 2rem);
    }
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 0 0;
  border-top: 2px solid $c-black;
}
