
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        







.btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-decoration: none !important;
  background: $black;
  border: 0;

  &::before {
    @include get-all-space;

    content: '';
    height: 110%;
    background: $tango;
    transform: translateX(-115%) skewX(-20deg);
    transition: transform 0.3s;
  }

  &:hover {
    cursor: pointer;

    &::before {
      transform: translateX(0) skewX(0);
    }
  }

  &:disabled {
    opacity: 0.3;
  }

  &.inverse {
    background: $white;

    &::before {
      background: $black;
    }
  }

  &.dark-orange {
    background: $tango-dark;
  }

  &.dark-orange,
  &.light {
    &::before {
      background: $c-white;
    }
  }
}

.btn__label {
  @include brown;
  // prettier-ignore
  @include fluid(font-size, (mob: 13px, xl: 18px));

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  color: $white;
  font-weight: 700;
  text-decoration: none !important;
  transition: color 0.3s;

  svg {
    fill: $c-white;
    transition: fill 0.3s;
  }

  svg:first-child {
    margin-right: 1rem;
  }

  .btn.light:hover & {
    color: $c-black;

    svg {
      fill: $c-black;
    }
  }

  .btn.dark-orange:hover &,
  .btn.inverse & {
    color: $tango;

    svg {
      fill: $tango;
    }
  }

  .btn.inverse:hover & {
    color: $white;

    svg {
      fill: $white;
    }
  }
}
