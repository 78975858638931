//Avoid typekit FOUT
// html {
//   &.js {
//     opacity: 0;
//     // transition: opacity 0.2s ease-in-out;

//     &.wf-active,
//     &.wf-inactive,
//     &.ready {
//       opacity: 1;
//     }
//   }
// }

html {
  -webkit-tap-highlight-color: transparent;
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

a,
a:active,
a:hover,
a:visited {
  color: inherit;
  text-decoration: inherit;
}

sup {
  font-size: 1.3rem;
  line-height: 1;
  vertical-align: initial;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: col(2);
  padding-left: col(2);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(xxxl) {
    padding-right: calc(#{$content-width} / 24 * 3);
    padding-left: calc(#{$content-width} / 24 * 3);
  }
}

.tada-wrapper {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.tada-content {
  display: inline-block;
  will-change: transform;
}

.grecaptcha-badge {
  z-index: -1;
}

@keyframes cookiebar {
  to {
    opacity: 1;
  }
}

// COOKIEBAR
.cookiebar {
  position: relative;
  z-index: 99;
  font-weight: 400;
  opacity: 0;
  animation: cookiebar 0.3s 2s forwards;

  * {
    font-weight: 400;
  }
}

.cookiebar-selection-outer {
  /* stylelint-disable-next-line declaration-no-important */
  max-width: 550px !important;
  /* stylelint-disable-next-line declaration-no-important */
  max-height: 800px !important;
}

.cookiebar__action,
[class*='cookiebar__action--'] {
  padding: 1rem 2rem;
  border-radius: 10rem;
}

.cookiebar-selection__content__cookies {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: $c-white !important;
}

.cookiebar__actions {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 0 !important;
}

.cookiebar__item__details {
  /* stylelint-disable-next-line declaration-no-important */
  border-bottom: 1px solid $c-gray-lightest !important;

  h3 {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 2rem !important;
  }
}

.cookiebar__item__details__duration {
  margin-bottom: 20px 0 30px;
}

.cookiebar-selection {
  justify-content: center;
}

.cookiebar-selection__footer {
  width: 100%;
  border-top: 1px solid $c-gray-lightest;

  &::before {
    display: none;
  }
}

.cookiebar-controls {
  border: 1px solid $c-gray-lighter;
}
